import styled from 'styled-components'

export const Wrapper = styled.section`
  max-width: 60rem;
  margin: 3rem auto 0;
  padding: 0 3rem 3rem;
  color: ${({ theme }) => theme.darkgray};

  ${({ theme }) => theme.mq.tablet} {
    margin: 7rem auto 0;
    padding: 0 3rem 7rem;
  }
`

export const StatusCode = styled.p`
  color: ${({ theme }) => theme.gray};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.body.xxl};
  text-align: center;
`

export const Header = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.m};

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.l};
  }
`

export const Paragrapg = styled.h2`
  margin: 3rem 0;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.s};

  ${({ theme }) => theme.mq.tablet} {
    margin: 6.5rem 0 3.2rem;
  }
`

export const List = styled.ul`
  position: relative;
  margin-bottom: 3rem;

  ::before {
    position: absolute;
    left: -1.6rem;
    display: none;
    width: 0.5rem;
    height: 100%;
    background: ${({ theme }) => theme.red};
    content: '';

    ${({ theme }) => theme.mq.tablet} {
      display: block;
    }
  }

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 7rem;
  }
`

export const Item = styled.li`
  position: relative;
  margin-bottom: 1.6rem;

  :last-child {
    margin-bottom: 0;
  }

  span {
    position: absolute;
    left: -1.6rem;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    font-size: ${({ theme }) => theme.font.size.headers.s};

    ${({ theme }) => theme.mq.tablet} {
      left: -3.7rem;
    }
  }
`

export const ButtonContainer = styled.div`
  text-align: center;
`
