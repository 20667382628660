import React from 'react'

import * as S from 'src/assets/styles/404.styles'

import NotFoundLayout from 'src/components/layout/notFoundLayout'
import Seo from 'src/components/seo'
import Button from 'src/components/button/button'

const ERROR_CASES = [
  'Sprawdź, czy poprawnie wpisałeś adres URL',
  'Odśwież stronę www',
  'Wyczyść ciasteczka (cookies) oraz pliki pamięci podręcznej (cache)',
  'Skontaktuj się z nami telefonicznie i poinformuj o problemach',
]

const NotFoundPage: React.FC = () => (
  <NotFoundLayout>
    <Seo
      title='404 Przepraszamy. Nie znaleziono strony, której szukasz. - JM Car Company'
      description='Coś poszło nie tak i strona nie została znaleziona. Sprawdź co możesz zrobić.'
    />
    <S.Wrapper>
      <S.StatusCode>404</S.StatusCode>
      <S.Header>Strona nie została znaleziona</S.Header>
      <S.Paragrapg>Ups, coś poszło nie tak... co możesz zrobić?</S.Paragrapg>
      <S.List>
        {ERROR_CASES.map((errorCase, i) => (
          <S.Item key={errorCase}>
            <span>{i + 1}</span>
            {errorCase}
          </S.Item>
        ))}
      </S.List>
      <S.ButtonContainer>
        <Button path='/' varaint='arrow' direction='right'>
          Strona główna
        </Button>
      </S.ButtonContainer>
    </S.Wrapper>
  </NotFoundLayout>
)

export default NotFoundPage
