import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme } from 'src/assets/styles/theme'
import GlobalStyles from 'src/assets/styles/globalStyles'

import CookieConsent from '../cookieConsent/cookieConsent'

const NotFoundLayout: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <CookieConsent />
    <main>{children}</main>
  </ThemeProvider>
)

export default NotFoundLayout
